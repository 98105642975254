<template>
    <div class="container">
        <gadget-step-purchase class="mt-3 mb-3" :step="3" v-if="isStepVisible()"></gadget-step-purchase>

        <div class="support d-sm-block d-md-none">
            <user-regis-benefit></user-regis-benefit>
        </div>

        <div class="box">
            <section class="form pattern-customer">
                <div class="wrapper">
                    <h1>เข้าสู่ระบบ</h1>

                    <div class="input-field">
                        <label>หมายเลขโทรศัพท์/ชื่อผู้ใช้</label>
                        <input type="text"
                            maxlength="100"
                            v-model="form.username"
                            placeholder="หมายเลขโทรศัพท์ หรือ ชื่อผู้ใช้"
                            class="square lg" 
                            :class="{ 'invalid': error.username }" />
                    </div>
                    <div class="input-field mt-2">
                        <label>รหัสผ่าน</label>
                        <div class="input-password">
                            <input :type="isShowPassword ? 'text' : 'password'"
                                @keyup.enter="login()"
                                maxlength="50"
                                v-model="form.password"
                                placeholder="รหัสผ่าน"
                                class="square lg" 
                                :class="{ 'invalid': error.password }" />
                            <button class="show-password" @click="showPassword()"><b-icon :icon="isShowPassword ? 'eye-slash-fill' : 'eye-fill'"></b-icon></button>
                        </div>
                    </div>
                    <div class="mt-4 text-center">
                        <button class="btn btn-main btn-submit" @click="login()">เข้าสู่ระบบ</button>
                    </div>
                    <div class="menu">
                        <router-link :to="routerPath.FORGET_PASSWORD">ลืมรหัสผ่าน?</router-link>
                    </div>

                    <div class="mt-4 text-center or"><span>หรือ</span></div>

                    <div class="mt-4">
                        <button class="btn btn-brand btn-facebook" @click="loginByFacebook()">
                            <font-awesome-icon :icon="['fab', 'facebook']" class="icon" />
                            เข้าสู่ระบบด้วย facebook
                        </button>
                    </div>
                    <div class="mt-1">
                        <button class="btn btn-brand btn-google" @click="loginByGoogle()">
                            <font-awesome-icon :icon="['fab', 'google']" class="icon" />
                            เข้าสู่ระบบด้วย Gmail
                        </button>
                    </div>
                </div>

                <div class="term mt-4">
                    ข้าพเจ้าตกลงตาม <router-link :to="routerPath.STATIC_TERM" target="_blank">ข้อกำหนดการใช้งาน</router-link> และ <router-link :to="routerPath.STATIC_PRIVACY" target="_blank">นโยบายความเป็นส่วนตัว</router-link>
                </div>
            </section>

            <section class="link pattern-customer">
                <div class="wrapper">
                    <h2>เป็นลูกค้าใหม่?</h2>
                    <p>ซื้อยางกับ Yellowtire ข้อมูลถูกต้อง ราคาถูกใจ มั่นใจได้แน่นอน</p>

                    <div class="action">
                        <router-link class="btn btn-warning" :to="routerPath.REGISTER">สมัครสมาชิกใหม่</router-link>
                    </div>

                    <div class="support d-none d-md-block">
                        <user-regis-benefit textColor="#ccc"></user-regis-benefit>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Helper from '@/services/helper';
import RouterPath from '@/router/path';
import UserService from '@/services/userService';
import GoogleAuth from '@/services/googleAuth';
import UserRegisBenefit from '@/components/user/RegisBenefit';
import GadgetStepPurchase from '@/components/gadget/StepPurchase';

export default {
    components: { UserRegisBenefit, GadgetStepPurchase },
	data() {
        return {
            routerPath: RouterPath,
			isShowPassword: false,
            isActive: false,
            form: this.initForm(),
            error: this.initError()
        }
    },
    methods: {
        initForm() {
            return {
                username: '',
                password: '',
                recaptcha: null
            };
        },
        initError() {
            return {
                username: false,
                password: false
            };
        },
        async login() {
            if (this.validate()) {
                let loader = this.$modalLoader.render();

                this.form.recaptcha = await this.getRecaptchaToken();
                const result = await UserService.submitLogin(this.form);

                loader.hide();

                this.afterAuthorizeUser(result);
            }
        },
        async getRecaptchaToken() {
            await this.$recaptchaLoaded()

            const recaptcha = await this.$recaptcha('customerLogin');

            return recaptcha;
        },
        async loginByFacebook() {
            console.log(window.FB);
            window.FB.login((authResult) => {
                if (authResult.authResponse) {
                    window.FB.api('/me', async (fbResult) => {
                        let loader = this.$modalLoader.render();
                        const result = await UserService.submitLoginFacebook(fbResult, authResult.authResponse);
                        loader.hide();

                        this.afterAuthorizeUser(result);
                    });
                }
            });
        },
        async loginByGoogle() {
            const authResult = await GoogleAuth.auth();

            if (authResult) {
                let loader = this.$modalLoader.render();
                const result = await UserService.submitLoginGoogle(authResult.access_token);
                loader.hide();

                this.afterAuthorizeUser(result);
            }
        },
        afterAuthorizeUser(result) {
            if (result.data?.success) {
                UserService.setUserToken(result.data.token);
                UserService.setUsername(result.data.username);

                this.$root.$emit('rootSetCartIcon');
                this.$root.$emit('rootSetUserDisplay');

                if (localStorage.cartData) {
                    this.$router.push(RouterPath.CART);
                } else if (window.history.length > 0) {
                    this.$router.go(-1);
                } else {
                    this.$router.push('/');
                }
            } else {
                this.$swal(Helper.warningAlert('เข้าสู่ระบบไม่สำเร็จ', result.data?.message));
            }
        },
        validate() {
            this.error = this.initError();
            let isValid = true;
            let isBlank = false;

            // Check Blank
            for (const property in this.error) {
                if (!this.form[property]?.trim()) {
                    this.error[property] = true;
                    isValid = false;
                    isBlank = true;
                }
            }

            // Popup
            if (isBlank) {
                this.$swal(Helper.warningAlert('เข้าสู่ระบบไม่สำเร็จ', 'โปรดกรอกข้อมูลให้ครบถ้วน'));
            }

            return isValid;
        },
        showPassword() {
            this.isShowPassword = !this.isShowPassword;
        },
        isStepVisible() {
            if (localStorage.cartData) {
                return true;
            }

            return false;
        }
    },
    metaInfo() {
		const title = 'เข้าสู่ระบบสมาชิก | YELLOWTiRE';
		const desc = 'เข้าสู่ระบบสมาชิก YELLOWTiRE';
		const image = location.origin + Helper.metaDefault().logo;
		
        return {
            title: title,
            meta: [
                { vmid: 'author', name: 'author', content: Helper.metaDefault().author },
				{ vmid: 'publisher', name: 'publisher', content: Helper.metaDefault().publisher },
                { vmid: 'keyword', name: 'keywords', content: Helper.metaDefault().keyword },
                { vmid: 'desc', name: 'description', content: desc },
                { vmid: 'og-url', property: 'og:url', content: location.href },
                { vmid: 'og-title', property: 'og:title', content: title },
                { vmid: 'og-desc', property: 'og:description', content: desc },
                { vmid: 'og-type', property: 'og:type', content: 'website' },
                { vmid: 'og-image', property: 'og:image', content: image },
                { vmid: 'twitter-description', property: 'twitter:description', content: desc },
                { vmid: 'twitter-title', property: 'twitter:title', content: title },
                { vmid: 'twitter-image', property: 'twitter:image', content: image }
            ]
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/components/user/style/Authentication.scss';
</style>