export default {
    getConfig() {
        return {
            client_id: '638708859598-4qvt7j3rajp0tb2nalacup2pgoqgcnkc.apps.googleusercontent.com',
            scope: [
                'https://www.googleapis.com/auth/userinfo.profile',
                'https://www.googleapis.com/auth/userinfo.email'
            ].join(' '),
            callback: ''
        };
    },
    async auth() {
        const client = window.google.accounts.oauth2.initTokenClient(this.getConfig());
    
        const response = await new Promise((resolve, reject) => {
            try {
                client.callback = (resp) => {
                    if (resp.error !== undefined) {
                        reject(resp);
                    }
            
                    resolve(resp);
                };
                
                client.requestAccessToken({ prompt: 'consent' });
            } catch (err) {
                console.log(err);
            }
        });
    
        return response;
    }
}