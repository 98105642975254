<template>
    <section class="benefit">
		<swiper :options="swiperOptions" class="sliders">
			<swiper-slide v-for="(item, index) in dataItems" :key="index">
                <div class="item">
					<div class="picture">
						<img :src="require('@/assets/seller-logo-' + (index + 1) + '.png')" :alt="item.title" :title="item.title" />
					</div>
					<div class="text" :style="{ '--textColor': textColor }">
						<strong>{{ item.strong }}</strong>
						<span>{{ item.text }}</span>
					</div>
				</div>
			</swiper-slide>
		</swiper>
	</section>
</template>

<script>
export default {
	data() {
        return {
            swiperOptions: {
                loop: true,
                autoplay: {
                    delay: 4000
                },
                pagination: {
                    el: '.swiper-pagination'
                }
            },
			dataItems: [
				{
					title: 'Yellowtire DBD',
					strong: 'ได้รับอนุญาตจากกระทรวงพาณิชย์ และผ่านการรับรอง โดยสำนักงานคณะกรรมการคุ้มครองผู้บริโภค(สคบ.)',
					text: 'เป็นแพลตฟอร์มอีคอมเมิร์ซ สำหรับยางรถยนต์โดยเฉพาะ'
				},
				{
					title: 'Yellowtire ECO and TSI',
					strong: 'มีข้อมูลเอกสารรับรองคุณภาพสินค้าครบ และถูกต้องมากที่สุด',
					text: 'ประเทศผลิต ใบมอก. มาตรฐาน ECO อัตราบรรทุกและความเร็วสูงสุด'
				},
				{
					title: 'Yellowtire VISA Master GBPrime',
					strong: 'ชำระเงินปลอดภัย ผ่านระบบชำระเงินออนไลน์ GB Prime Pay',
					text: 'หลากหลายช่องทางชำระเงิน ทั้ง โอนเงิน บัตรเครดิต ระบบผ่อนชำระ'
				},
				{
					title: 'Yellowtire Seller Service',
					strong: 'ได้รับการแต่งตั้งจากร้านยางชั้นนำ ให้เป็นผู้แทนจำหน่ายอย่างเป็นทางการ',
					text: 'คุณจึงซื้อยางได้มั่นใจ เลือกร้าน เลือกเวลาติดตั้งที่สะดวก'
				}
			]
        }
    },
	props: {
		textColor: {
            type: String,
            default: '#666'
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

$textColor: var(--textColor);

.benefit {
	.sliders {
        overflow: hidden;

        .item {
			text-align: center;

			.picture {
				display: inline-block;
				background: #fff;
				padding: 4px;

				img {
					height: 25px;
				}
			}

			.text {
				margin-top: 15px;
				color: $textColor;
				font-size: 11px;
				line-height: 14px;

				@media only screen and (max-width: $screenSmall) {
					margin-top: 5px;
				}
			}
		}
    }
}
</style>